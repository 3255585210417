<template>
  <div class="bg-primary text-light">
    <div class="container text-center p-1 small">This website is currently in beta</div>
  </div>
</template>

<script>
export default {
  name: 'Beta',
};
</script>
