import { createStore } from 'vuex';
import { auth } from './auth.module';
import { homeOwner } from './home-owner.module';

const store = createStore({
  modules: {
    auth,
    homeOwner,
  },
  state: {
    isBusy: false,
    navOpen: false,
  },
  actions: {
    toggleNavigation({ commit, state }) {
      commit('setNavOpen', !state.navOpen);
    },
    closeMobileMenu({ commit }) {
      commit('setNavOpen', false);
    },
  },
  mutations: {
    setIsBusy(state, newState) {
      state.isBusy = newState;
    },
    setNavOpen(state, newState) {
      state.navOpen = newState;
    },
  },
});

export default store;
