import { createWebHistory, createRouter } from 'vue-router';
import logoutCleanup from './services/logout-cleanup';
import Login from './components/Login.vue';
import ForgottenPassword from './components/ForgottenPassword.vue';
import Register from './components/Register.vue';

import store from './store';

const Homepage = () => import('./components/Homepage.vue');
const FindOutMore = () => import('./components/FindOutMore.vue');

const Properties = () => import('./components/Properties.vue');
const Property = () => import('./components/Property.vue');
const Lodgement = () => import('./components/Lodgement.vue');
const Measure = () => import('./components/Measure.vue');
const PropertyNotFound = () => import('./components/Homepage.vue');

const performLogoutWithLoginRedirect = () => {
  logoutCleanup();
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: performLogoutWithLoginRedirect,
  },
  {
    path: '/register',
    component: Register,
    meta: {
      title: 'Register',
    },
  },
  {
    path: '/forgotten-password',
    name: 'forgottenpassword',
    component: ForgottenPassword,
    meta: {
      title: 'Forgotten Password',
    },
  },
  // {
  //   path: '/reset-password',
  //   component: ResetPassword,
  //   meta: {
  //     title: 'Reset',
  //   },
  // },
  {
    path: '/',
    name: 'home',
    component: Homepage,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/find-out-more',
    name: 'findoutmore',
    component: FindOutMore,
    meta: {
      title: 'Find Out More',
    },
  },
  {
    path: '/properties',
    name: 'properties',
    component: Properties, // lazy-loaded
    meta: {
      title: 'Properties',
    },
  },
  {
    path: '/property/:uprn',
    name: 'property',
    component: Property, // lazy-loaded
    meta: {
      title: 'Property',
    },
  },
  {
    path: '/property/:uprn/:lodgementId',
    name: 'lodgement',
    component: Lodgement, // lazy-loaded
    meta: {
      title: 'Lodgement',
    },
  },
  {
    path: '/property/:uprn/:lodgementId/:measureId',
    name: 'measure',
    component: Measure, // lazy-loaded
    meta: {
      title: 'Measure',
    },
  },
  {
    path: '/property-not-found',
    name: 'propertynotfound',
    component: PropertyNotFound, // lazy-loaded
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/',
    '/login/',
    '/register/',
    '/forgotten-password/',
    '/reset-password/',
    '/find-out-more/',
  ];

  const bothPages = ['/', '/find-out-more/'];

  const authRequired = !publicPages.includes(to.path) && !publicPages.includes(`${to.path}/`);
  const isPublicPage = publicPages.includes(to.path) || publicPages.includes(`${to.path}/`);
  const isBothPage = bothPages.includes(to.path) || bothPages.includes(`${to.path}/`);
  const loggedIn = localStorage.getItem('account');

  // Mobile menu
  store.commit('setNavOpen', false);

  // Redirect to login if not logged in and attempting to access route that requires permission
  if (authRequired && !loggedIn) {
    next('/login');
  } else if (isPublicPage && loggedIn && !isBothPage) {
    localStorage.removeItem('account');
    window.location = to.path;
  } else {
    next();
  }
});

export default router;
