<template>
  <ul :class="navClasses">
    <li class="nav-item">
      <router-link class="nav-link active" :to="{ path: '/' }" @click="hideNavigation"
        >Home</router-link
      >
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ path: '/find-out-more/' }" @click="hideNavigation"
        >Find out more</router-link
      >
    </li>
    <li class="nav-item">
      <a class="nav-link" href="https://www.trustmark.org.uk/news">News</a>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ path: '/properties/' }" @click="hideNavigation"
        >My Property</router-link
      >
    </li>
    <li v-if="!mobile" class="nav-item">
      <button v-if="auth.authenticated" type="button" class="nav-link btn" @click="logoutAction">
        Log out
      </button>
      <router-link
        v-else
        class="nav-link btn btn-secondary"
        :to="{ path: '/login/' }"
        @click="hideNavigation"
        >Login</router-link
      >
    </li>
    <li v-if="mobile" class="nav-item">
      <router-link
        v-if="auth.authenticated"
        class="nav-link"
        :to="{ path: '/' }"
        @click="logoutAction"
        >Log out</router-link
      >
      <router-link v-else class="nav-link" :to="{ path: '/login/' }" @click="hideNavigation"
        >Login</router-link
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ConnectedNav',
  props: {
    mobile: Boolean,
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    navClasses() {
      return this.mobile ? 'nav flex-column py-3' : 'nav';
    },
  },
  methods: {
    hideNavigation() {
      this.$store.dispatch('closeMobileMenu');
    },
    logoutAction() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
