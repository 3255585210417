<template>
  <div v-show="isBusy" id="" class="h-100" :class="{ loading: isBusy }">
    <div class="loading-dots">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusySpinner',
  computed: {
    isBusy() {
      return this.$store.state.isBusy;
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
