import { HTTP, authHeader } from './http-service';

class HomeOwnerService {
  getProperties() {
    return HTTP.get(`properties`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getProperty(uprn) {
    return HTTP.get(`property/${uprn}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  sendCertificate(path) {
    return HTTP.get(`certificatesend/${path}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new HomeOwnerService();
