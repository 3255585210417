<template>
  <div class="homepage-banner py-5" style="background: purple">
    <img
      :src="myLogoSrc"
      alt="my-logo"
      style="display: block; margin-left: auto; margin-right: auto; width: 50%"
    />
    <div class="container py-md-5">
      <div class="homepage-promo">
        <div class="row text-center mb-2">
          <div class="col">
            <h1 class="text-white">{{ title }}</h1>
            <p class="display-2 text-white">{{ text }}</p>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col col-lg-7 bg-white rounded">
            <div class="py-2">
              <form class="row" action="/register/">
                <div class="col-md-8">
                  <div class="form-group mb-0">
                    <label for="email-address" class="sr-only">Email address</label>
                    <input
                      id="email-address"
                      v-model="email"
                      type="email"
                      class="form-control form-control-lg"
                      name="email-address"
                      placeholder="Your email address"
                    />
                  </div>
                </div>
                <div class="col-md-4 pl-md-0">
                  <button class="btn btn-secondary btn-lg btn-block" type="button" @click="submit">
                    Get started
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="row text-center text-white mt-3">
          <div class="col">
            <p class="display-3">
              Already have an account? <router-link :to="{ name: 'login' }">Login</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myLogoSrc from '@/images/bg-illustration.svg';

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      email: '',
      myLogoSrc,
    };
  },
  methods: {
    submit() {
      this.$router.push(`/register?email=${this.email}`);
    },
  },
};
</script>
