<template>
  <div class="w-100 overflow-hidden d-flex flex-column flex-lg-row">
    <main class="w-100 overflow-hidden">
      <connected-layout>
        <router-view />
      </connected-layout>
    </main>
  </div>
</template>

<script>
import ConnectedLayout from './ConnectedLayout.vue';

export default {
  name: 'WorkspaceLayout',
  components: {
    ConnectedLayout,
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
  },
};
</script>
