import axios from 'axios';
import logoutCleanup from './logout-cleanup';
import { HTTP, authHeader, setAuthLocalStorage, AUTH_API_URL } from './http-service';

class AuthService {
  login(user) {
    return axios
      .post(
        `${AUTH_API_URL}login`,
        {
          username: user.username,
          password: user.password,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.sessionToken) {
          setAuthLocalStorage(response);
        }
        return response.data;
      });
  }

  logout() {
    logoutCleanup();
  }

  fogottenPassword(emailAddress) {
    return axios.get(`${AUTH_API_URL}resetpassword/${emailAddress}`);
  }

  register(payload) {
    return axios.post(`${AUTH_API_URL}register`, payload);
  }

  verify(user) {
    return axios.post(`${AUTH_API_URL}register/verify`, user);
  }

  sendSms(payload) {
    const bearer = {
      Authorization: `Bearer ${payload.sessionToken}`,
    };
    return axios.post(
      `${AUTH_API_URL}authdevice/sendsms`,
      {},
      {
        headers: bearer,
      }
    );
  }

  verifySms(payload) {
    const bearer = {
      Authorization: `Bearer ${payload.sessionToken}`,
    };
    return axios.post(`${AUTH_API_URL}authdevice/verifysms`, payload, {
      headers: bearer,
      withCredentials: true,
    });
  }

  changeMobile(payload) {
    const bearer = {
      Authorization: `Bearer ${payload.sessionToken}`,
    };
    return axios.post(`${AUTH_API_URL}authdevice/changemobile`, payload, {
      headers: bearer,
    });
  }

  getAppData() {
    return HTTP.get('/appData', {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new AuthService();
