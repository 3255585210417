<template>
  <div>
    <div class="container">
      <div class="row justify-content-center py-md-5">
        <div class="col-lg-8">
          <div class="shadow rounded p-5">
            <h1 class="h3">Register</h1>
            <div v-if="errorMessage" class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
            <div class="form-group">
              <label>Email address</label>
              <input ref="emailAddress" v-model="ownerEmail" type="email" class="form-control" />
            </div>
            <div class="form-group">
              <label>Authentication code (available on your certificate)</label>
              <input
                ref="LodgementAuthCode"
                v-model="lodgementAuthCode"
                type="text"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Certificate Number</label>
              <input
                ref="CertificateNumber"
                v-model="certificateNumber"
                type="text"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Choose a password</label>
              <input ref="Password" v-model="password" type="password" class="form-control" />
            </div>
            <div class="form-group">
              <label>Confirm password</label>
              <input ref="Confirm" v-model="confirm" type="password" class="form-control" />
            </div>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="hasValidForm"
              @click="registerAction"
            >
              Register
            </button>
          </div>
          <p class="mt-3">Already registered? <router-link to="/app/login">Login</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      errorMessage: null,
      ownerEmail: this.$route.query.email,
      password: '',
      confirm: '',
      lodgementAuthCode: '',
      certificateNumber: '',
    };
  },
  computed: {
    hasValidForm() {
      return !this.certificateNumber || !this.ownerEmail || !this.password || !this.confirm;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  },
  methods: {
    registerAction() {
      const payload = {
        ownerEmail: this.ownerEmail,
        password: this.password,
        lodgementAuthCode: this.lodgementAuthCode,
        certificateNumber: this.certificateNumber,
      };
      this.$store
        .dispatch('auth/register', payload)
        .then((resp) => {
          this.message = resp.message;
        })
        .catch((err) => {
          if (err.errorCode && err.errorCode === 'TOO_MANY_PROPERTIES') {
            this.errorMessage =
              'We cannot currently register you on this service due to the number of propertes you have associated with this email address';
          } else if (err.message) {
            this.errorMessage = err.message;
          } else {
            this.errorMessage = err;
          }
          window.scrollTo(0, 0);
        });
    },
  },
};
</script>
