<template>
  <div>
    <div class="container">
      <div class="row justify-content-center py-5">
        <div class="col-lg-8">
          <div class="form-group">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
          </div>
          <div class="shadow rounded p-4 p-md-5">
            <h1 class="h3">Request Password Reset</h1>
            <div v-if="errorMessage" class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
            <div>
              <div class="form-group">
                <label>Email address</label>
                <input
                  ref="emailAddress"
                  v-model="emailAddress"
                  type="email"
                  class="form-control"
                />
              </div>
              <button
                type="button"
                class="btn btn-primary"
                :disabled="hasEmailAddress"
                @click="forgottenPasswordAction"
              >
                Send reset code
              </button>
            </div>
            <p class="mt-3"><router-link to="/app/login">Login</router-link></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgottenPassword',
  data() {
    return {
      emailAddress: null,
      message: null,
    };
  },
  computed: {
    hasEmailAddress() {
      return !this.emailAddress;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  },
  methods: {
    forgottenPasswordAction() {
      this.$store.dispatch('auth/fogottenPassword', this.emailAddress).then((resp) => {
        this.message = resp.message;
      });
    },
  },
};
</script>
