<template>
  <!-- <connected-layout> -->
  <div class="container">
    <!-- <SEO title="Login" /> -->
    <div class="row justify-content-center py-5">
      <div class="col-lg-8">
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
        <div class="shadow rounded p-4 p-md-5">
          <h1 class="h3">Login</h1>
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
          <div v-if="!authenticated">
            <div class="form-group">
              <label for="Username">Email address</label>
              <input
                id="Username"
                v-model="email"
                type="email"
                class="form-control"
                name="Username"
                required
              />
            </div>
            <div class="form-group">
              <label for="Password">Choose a password</label>
              <input
                id="Password"
                v-model="password"
                type="password"
                class="form-control"
                name="Password"
                required
              />
            </div>
            <button type="button" class="btn btn-primary" :loading="loading" @click="handleLogin">
              Login
            </button>
          </div>
        </div>
        <p v-if="!authenticated" class="mt-3">
          Don’t have an account?
          <router-link to="/register">Register here</router-link>
        </p>
        <p v-if="!authenticated">
          <router-link to="/forgotten-password">Forgotten your password?</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import { helpers, required, email, maxLength } from '@vuelidate/validators';

export default {
  name: 'Login',
  data() {
    return {
      v$: useValidate(),
      email: '',
      password: '',
      loading: false,
      message: '',
      isNewDevice: false,
      receivedSmsCode: '',
      maskedMobile: null,
      success: false,
      buildDate: window.BUILD_DATE.toISOString(),
      errorMessage: null,
    };
  },
  validations: {
    email: {
      isRequired: helpers.withMessage('Email address is required', required),
      isEmail: helpers.withMessage('Must be a valid email address', email),
    },
    password: {
      isRequired: helpers.withMessage('Password is required', required),
    },
    mobileNumber: {
      maxLength: maxLength(12),
      // isRequired: helpers.withMessage('Mobile number is required', (value, vm) => {
      //   if (!vm.isNewDevice) {
      //     return true;
      //   }
      //   return vm.maskedMobile !== null;
      // }),
      // isPhoneNumber: helpers.withMessage('Phone number must be in a valid format', isPhoneNumber),
    },
    isNewDevice: {},
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    smsCode() {
      return this.$store.state.auth.smsCode;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ name: 'properties' });
    }
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  },
  methods: {
    changeMobile() {
      this.maskedMobile = null;
    },
    viewSendSms() {
      this.$store.commit('auth/setSmsCode', 'required', { root: true });
    },
    saveMobileNumber() {
      this.message = null;
      const payload = {
        mobileNumber: this.mobileNumber,
        sessionToken: this.smsToken,
      };
      this.$store
        .dispatch('auth/changeMobile', payload)
        .then(() => {
          this.maskedMobile = this.mobileNumber;
        })
        .catch((error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
    resetLogin() {
      this.message = null;
      this.isNewDevice = false;
      this.receivedSmsCode = '';
    },
    handleLogin() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message = 'Please enter your email address and password to login.';
        window.scrollTo(0, 0);
        return;
      }

      this.loading = true;
      this.message = '';

      const user = {
        username: this.email,
        password: this.password,
      };

      this.$store.dispatch('auth/login', user).then(
        () => {
          this.loading = false;
          this.success = true;
          window.scrollTo(0, 0);
          this.$router.push({ name: 'properties' });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    sendSmsCode() {
      this.loading = false;
      const payload = {
        sessionToken: this.smsToken,
      };
      this.$store.dispatch('auth/smsRequestCode', payload).catch((error) => {
        this.loading = false;
        this.message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
      });
    },
    verifySmsCode() {
      this.loading = false;
      const payload = {
        sessionToken: this.smsToken,
        smsCode: this.receivedSmsCode,
      };
      this.$store
        .dispatch('auth/smsVerifyCode', payload)
        .then(() => {
          this.handleLogin();
        })
        .catch((error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
  },
};
</script>

<style>
.container-login {
  max-width: 740px !important;
  padding: 40px 40px;
  margin: auto;
}

label {
  margin-top: 10px;
}
</style>
