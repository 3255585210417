import HomeOwnerService from '../services/home-owner-service';

export const homeOwner = {
  namespaced: true,
  state: {
    properties: null,
    property: null,
    certificateSend: null,
  },
  actions: {
    getProperties({ commit }) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setProperties', null);
      return HomeOwnerService.getProperties().then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setProperties', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    getProperty({ commit }, uprn) {
      commit('setIsBusy', true, {
        root: true,
      });
      commit('setProperty', null);
      return HomeOwnerService.getProperty(uprn).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setProperty', result);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
    sendCertificate({ commit }, path) {
      commit('setIsBusy', true, {
        root: true,
      });
      return HomeOwnerService.sendCertificate(path).then(
        (result) => {
          commit('setIsBusy', false, {
            root: true,
          });
          commit('setCertificateSend', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, {
            root: true,
          });
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setProperties(state, newProperties) {
      if (!newProperties) {
        state.properties = null;
      } else {
        state.properties = newProperties.properties;
      }
    },
    setProperty(state, newProperty) {
      state.property = newProperty;
    },
    setCertificateSend(state, newCertificateSend) {
      state.certificateSend = newCertificateSend;
    },
  },
};
