<template>
  <div>
    <!-- <helmet v-if="navOpen" :body-attributes="{ class: 'overflow-hidden' }" /> -->
    <connected-header :site-title="siteTitle" />

    <homepage-banner
      v-if="isHomePage"
      title="Welcome to the TrustMark Property Hub"
      text="Do you have a TrustMark Certificate of Lodgement? If so, enter your email address to get started."
    ></homepage-banner>

    <main class="main">
      <slot />
    </main>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-3">
            <ul class="list-unstyled">
              <li>
                <router-link to="/">Home</router-link>
              </li>
              <li>
                <router-link to="/find-out-more/">Find out more</router-link>
              </li>
              <li>
                <a href="https://www.trustmark.org.uk/news">News</a>
              </li>
              <li>
                <router-link to="/properties/">My Property</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-4 col-lg-3">
            <ul class="list-unstyled">
              <li>
                <a href="https://www.trustmark.org.uk/privacy">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div class="col text-md-right">
            <img :src="logoBis" class="img img-fluid" style="width: 170px" />&nbsp;&nbsp;
            <img :src="logoSem" class="img img-fluid" style="width: 130px" />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import { VueHelmet as Helmet } from 'vue-helmet';
import ConnectedHeader from './ConnectedHeader.vue';
// import backgroundIllustration from '../images/bg-illustration.svg';
import logoSem from '../images/logo-sem.png';
import logoBis from '../images/logo-bis.png';
import HomepageBanner from './HomepageBanner.vue';

export default {
  name: 'ConnectedLayout',
  components: {
    // Helmet,
    ConnectedHeader,
    HomepageBanner,
  },
  props: {
    banner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    navOpen() {
      return this.$store.state.navOpen;
    },
    siteTitle() {
      return 'Trustmark Property Hub';
    },
    logoBis() {
      return logoBis;
    },
    logoSem() {
      return logoSem;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isHomePage() {
      return this.$route.name === 'home' && !this.currentAccount;
    },
  },
};
</script>

<style lang="scss" scoped>
// Your styles here
</style>
