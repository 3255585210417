import AuthService from '../services/auth-service.js';
import { getAccountFromLocalStorage } from '../services/http-service.js';

const accountFromStorage = getAccountFromLocalStorage();
const initialState = accountFromStorage
  ? { status: { loggedIn: true }, account: accountFromStorage, authenticated: true }
  : { status: { loggedIn: false }, account: null, authenticated: false };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      commit('setIsBusy', true, { root: true });
      return AuthService.login(user).then(
        (loginResult) => {
          commit('setIsBusy', false, { root: true });
          if (loginResult.sessionToken) {
            // AuthService.getAppData().then((resp) => {
            //  commit('setAppData', resp);
            // });
            commit('loginSuccess', loginResult);
            return Promise.resolve(loginResult);
          }
          commit('setSmsCode', 'verified');
          return Promise.resolve(loginResult);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          commit('loginFailure');
          if (error.response && error.response.status === 403) {
            throw new Error('You do not have permission to login.');
          }
          return Promise.reject(error);
        }
      );
    },
    register({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return AuthService.register(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result.data);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error.response.data);
        }
      );
    },
    fogottenPassword({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return AuthService.fogottenPassword(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result.data);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    smsRequestCode({ commit }, payload) {
      return AuthService.sendSms(payload)
        .then((resp) => {
          if (resp.data.isSuccess) {
            commit('setSmsCode', 'requested');
            return Promise.resolve();
          }
          throw new Error('Failed to request an SMS');
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    smsVerifyCode({ commit }, payload) {
      return AuthService.verifySms(payload)
        .then((resp) => {
          if (resp.data.isSuccess) {
            commit('setIsBusy', true, {
              root: true,
            });
            commit('setSmsCode', 'verified');
            return Promise.resolve();
          }
          throw new Error('Failed to verify SMS');
        })
        .catch((err) => {
          commit('setIsBusy', false, {
            root: true,
          });
          if (err.response.data.message) {
            return Promise.reject(err.response.data.message);
          }
          return Promise.reject(err);
        });
    },
    changeMobile({ commit }, payload) {
      return AuthService.changeMobile(payload)
        .then((resp) => {
          if (resp.data.isSuccess) {
            commit('setIsBusy', false, { root: true });
            commit('setSmsCode', 'requested');
            return Promise.resolve();
          }
          throw new Error('Failed to change your Mobile Number');
        })
        .catch((err) => {
          if (err.response.data.message) {
            return Promise.reject(err.response.data.message);
          }
          return Promise.reject(err);
        });
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    getAppData({ commit, dispatch }) {
      commit('setIsBusy', true, { root: true });
      return AuthService.getAppData()
        .then((resp) => {
          commit('setAppData', resp);
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(resp);
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            dispatch('logout');
          }
        });
    },
    verify({ commit }, user) {
      commit('setIsBusy', true, { root: true });
      return AuthService.verify(user).then(
        (response) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          if (error.response.status === 422) {
            if (error.response.data.reasons) {
              const reasons = error.response.data.reasons
                .map((x) => `${x.field} ${x.reason}`)
                .join(', ');
              return Promise.reject(reasons);
            }
            if (error.response.data.message) {
              return Promise.reject(error.response.data.message);
            }
          }
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, loginResult) {
      state.status.loggedIn = true;
      state.authenticated = loginResult && loginResult.sessionToken !== null;
      state.account = loginResult;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.account = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.authenticated = false;
      state.account = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    setAppData(state, newState) {
      state.appData = newState;
    },
    setSmsCode(state, newState) {
      state.smsCode = newState;
    },
  },
};
