<template>
  <header class="header sticky-top shadow">
    <div class="container py-3">
      <div class="row">
        <div class="col-8 col-md-6 col-lg-3 d-flex align-items-center">
          <h1 class="m-0">
            <img :src="logo" :alt="siteTitle" class="img img-fluid d-block" />
          </h1>
        </div>
        <div
          class="col-4 col-md-6 col-lg-4 d-flex justify-content-end align-items-center d-lg-none"
        >
          <button class="btn btn-lg btn-link" aria-label="Show menu" @click="toggleNavigation">
            <font-awesome-icon :icon="navOpen ? faTimes : faBars" />
          </button>
        </div>
        <div class="col-md-9 text-right d-none d-lg-flex justify-content-end align-items-center">
          <connected-nav />
        </div>
      </div>
    </div>
    <beta />
  </header>
  <div v-if="navOpen" class="d-lg-none mobile-nav">
    <div class="container">
      <connected-nav :mobile="true" />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Beta from './Beta.vue';
import ConnectedNav from './ConnectedNav.vue';

export default {
  name: 'ConnectedHeader',
  components: {
    ConnectedNav,
    Beta,
    FontAwesomeIcon,
  },
  props: {
    siteTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    navOpen() {
      return this.$store.state.navOpen;
    },
    logo() {
      // eslint-disable-next-line global-require
      return require('../images/logo@2x.png');
    },
    faBars() {
      return faBars;
    },
    faTimes() {
      return faTimes;
    },
  },
  methods: {
    toggleNavigation() {
      this.$store.dispatch('toggleNavigation');
    },
  },
};
</script>
