import axios from 'axios';
import logoutCleanup from './logout-cleanup';

export const API_URL = process.env.VUE_APP_API_URL;
export const API_ADDRESS_URL = process.env.VUE_APP_API_ADDRESS_URL;
export const AUTH_API_URL = process.env.VUE_APP_AUTH_API_URL;

export const HTTP = axios.create({
  baseURL: API_URL,
});

export const HTTP_ADDRESS = axios.create({
  baseURL: API_ADDRESS_URL,
});

export const setAuthLocalStorage = function setAuthLocalStorage(response) {
  if (response.data.sessionToken) {
    localStorage.setItem('account', JSON.stringify(response.data));
  }
};

export const getAccountFromLocalStorage = function getAccountFromLocalStorage() {
  return JSON.parse(localStorage.getItem('account'));
};

export const authHeader = function authHeader() {
  const account = getAccountFromLocalStorage();
  if (account) {
    return { Authorization: `Bearer ${account.sessionToken}` };
  }
  return {};
};

function onError(error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest.retry) {
    originalRequest.retry = true;

    if (!getAccountFromLocalStorage()) {
      logoutCleanup();
      return Promise.reject(new Error('Session has expired - login is required'));
    }

    const refreshRequest = {
      refreshToken: getAccountFromLocalStorage().refreshToken,
      username: getAccountFromLocalStorage().accountName,
    };

    return axios
      .post(`${API_URL}auth/refresh`, refreshRequest, {
        withCredentials: true,
      })
      .then((resp) => {
        if (resp.status === 200) {
          setAuthLocalStorage(resp);

          originalRequest.headers.Authorization = `Bearer ${
            getAccountFromLocalStorage().sessionToken
          }`;
          return axios(originalRequest);
        }

        logoutCleanup();
        return Promise.reject(new Error('Refresh was not successful - login is required'));
      })
      .catch(() => {
        logoutCleanup();
        return Promise.reject(new Error('Failed to refresh - login is required'));
      });
  }
  if (error.response.status === 422) {
    if (error.response.data.reasons) {
      const reasons = error.response.data.reasons.map((x) => `${x.field} ${x.reason}`).join(', ');
      return Promise.reject(reasons);
    }
    if (error.response.data.message) {
      return Promise.reject(error.response.data.message);
    }
  }
  return Promise.reject(error);
}

HTTP.interceptors.response.use((response) => {
  return response;
}, onError);

HTTP_ADDRESS.interceptors.response.use((response) => {
  return response;
}, onError);
