<template>
  <div>
    <workspace-layout />

    <busy-spinner />
  </div>
</template>

<script>
import WorkspaceLayout from './components/WorkspaceLayout.vue';
import BusySpinner from './components/BusySpinner.vue';

export default {
  name: 'App',
  components: {
    WorkspaceLayout,
    BusySpinner,
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;

  .btn-secondary {
    color: white;
  }
}

/* Dialog start */
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 10;
}

.window {
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* Dialog end */
</style>
